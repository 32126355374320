// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptable-use-policy-js": () => import("/opt/build/repo/src/pages/acceptable-use-policy.js" /* webpackChunkName: "component---src-pages-acceptable-use-policy-js" */),
  "component---src-pages-chrome-js": () => import("/opt/build/repo/src/pages/chrome.js" /* webpackChunkName: "component---src-pages-chrome-js" */),
  "component---src-pages-crawler-js": () => import("/opt/build/repo/src/pages/crawler.js" /* webpackChunkName: "component---src-pages-crawler-js" */),
  "component---src-pages-firefox-js": () => import("/opt/build/repo/src/pages/firefox.js" /* webpackChunkName: "component---src-pages-firefox-js" */),
  "component---src-pages-login-reset-password-js": () => import("/opt/build/repo/src/pages/login/reset-password.js" /* webpackChunkName: "component---src-pages-login-reset-password-js" */),
  "component---src-pages-opera-js": () => import("/opt/build/repo/src/pages/opera.js" /* webpackChunkName: "component---src-pages-opera-js" */),
  "component---src-pages-playlist-add-js": () => import("/opt/build/repo/src/pages/playlist/add.js" /* webpackChunkName: "component---src-pages-playlist-add-js" */),
  "component---src-pages-playlist-index-js": () => import("/opt/build/repo/src/pages/playlist/index.js" /* webpackChunkName: "component---src-pages-playlist-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-publishers-js": () => import("/opt/build/repo/src/pages/publishers.js" /* webpackChunkName: "component---src-pages-publishers-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("/opt/build/repo/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

